exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coparenting-tsx": () => import("./../../../src/pages/coparenting.tsx" /* webpackChunkName: "component---src-pages-coparenting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-pua-download-tsx": () => import("./../../../src/pages/pua-download.tsx" /* webpackChunkName: "component---src-pages-pua-download-tsx" */),
  "component---src-pages-reservation-tsx": () => import("./../../../src/pages/reservation.tsx" /* webpackChunkName: "component---src-pages-reservation-tsx" */),
  "component---src-pages-safequestionaire-tsx": () => import("./../../../src/pages/safequestionaire.tsx" /* webpackChunkName: "component---src-pages-safequestionaire-tsx" */),
  "component---src-pages-seminar-tsx": () => import("./../../../src/pages/seminar.tsx" /* webpackChunkName: "component---src-pages-seminar-tsx" */),
  "component---src-pages-servicenote-tsx": () => import("./../../../src/pages/servicenote.tsx" /* webpackChunkName: "component---src-pages-servicenote-tsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

