import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import * as amplitude from "@amplitude/analytics-browser";
import ChannelService from "./src/service/ChannelTalk";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  // amplitude
  const amplitudeApiKey = "2496ea23f74a87042111c5071361fee2";
  amplitude.init(amplitudeApiKey);

  // channelTalk
  ChannelService.loadScript();
  ChannelService.boot({
    pluginKey: "e56b43f8-d866-4474-9484-371cc1f92c65",
  });

  return <>{element}</>;
};
